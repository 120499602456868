<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
})

const iconComponent = defineAsyncComponent(() =>
  import(`./Icons/icon-${props.name}.vue`)
)
</script>

<template>
  <span class="icon svg" :class="`svg--${name}`">
    <component :is="iconComponent" />
  </span>
</template>

<style lang="scss">
.svg {
  svg {
    display: block;
    fill: currentColor;
    width: 100%;
    height: 100%;
  }

  // Fixes for svg that are expanding for no reason on iOS
  &--circle {
    svg {
      width: unset;
      height: unset;
    }
  }
}
</style>
